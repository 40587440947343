<template>
	<div class="app-container">
		<!--    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" title="Dialog Title">-->
		<h2 align="center">单位注册申请表</h2>
		<el-form ref="formData" :model="formData" :rules="rules" size="medium" label-width="100px" label-position="top"
			class="app_form">
			<el-row :gutter="10" class="app_form_no1">
				<el-col :span="6">
					<el-form-item class="app_form_no1_name" label="单位名称" prop="corpName">
						<el-input v-model="formData.corpName" placeholder="请输入单位名称" clearable :style="{width: '100%'}">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="注册地址" prop="registerAddress">
						<el-input v-model="formData.registerAddress" placeholder="请输入注册地址" clearable
							:style="{width: '100%'}"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="统一社会信用代码" prop="uniCode">
						<el-input v-model="formData.uniCode" placeholder="请输入统一社会信用代码" clearable
							:style="{width: '100%'}"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="5">
					<el-form-item label="单位性质" prop="corpNature">
						<!-- <el-input v-model="formData.corpNature" placeholder="请输入单位性质" clearable :style="{width: '90%'}">
						</el-input> -->
						<el-select v-model="formData.corpNature" placeholder="请输入单位性质" clearable
							:style="{width: '90%'}">
							<el-option v-for="(item, index) in corpNature" :key="index" :label="item.label"
								:value="item.value" :disabled="item.disabled"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10" class="app_form_no2">
				<el-col :span="6" class="app_form_no2_money">
					<el-form-item label="注册资金（万元）" prop="registerFund">
						<el-input-number v-model="formData.registerFund" placeholder="请输入注册资金（万元）" clearable
							:style="{width: '90%'}" :min="0"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="6" class="app_form_no2_time">
					<el-form-item label="成立时间" prop="formed">
						<el-date-picker v-model="formData.formed" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
							:style="{width: '100%'}" placeholder="请输入成立时间" clearable></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="通讯地址" prop="contAddress">
						<el-input v-model="formData.contAddress" placeholder="请输入通讯地址" clearable
							:style="{width: '100%'}"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="5">
					<el-form-item label="邮政编码" prop="postCode">
						<el-input v-model="formData.postCode" placeholder="请输入邮政编码" clearable :style="{width: '90%'}">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="4" class="app_form_no3">
					<el-row>
						<el-form-item label="技术领域" prop="techType">
							<el-select v-model="formData.techType" placeholder="请选择技术领域" clearable
								:style="{width: '100%'}">
								<el-option v-for="(item, index) in techTypeOptions" :key="index" :label="item.label"
									:value="item.value" :disabled="item.disabled"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="单位类别" prop="corpType">
							<el-select v-model="formData.corpType" placeholder="请选择单位类别" clearable
								:style="{width: '100%'}">
								<el-option v-for="(item, index) in corpTypeOptions" :key="index" :label="item.label"
									:value="item.value" :disabled="item.disabled"></el-option>
							</el-select>
						</el-form-item>
					</el-row>
				</el-col>
				<el-col :span="16">
					<el-form-item label="主要产品（服务）及技术" prop="mainProduct">
						<el-input v-model="formData.mainProduct" type="textarea" placeholder="请输入主要产品（服务）及技术"
							:autosize="{minRows: 6, maxRows: 6}" :style="{width: '100%'}"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-divider content-position="left">单位人员情况</el-divider>

			<el-row :gutter="10">
				<el-row class="app_form_no4">
					<el-col :span="4">
						<el-form-item label="职工总数" prop="staffTotal">
							<el-input-number v-model="formData.staffTotal" placeholder="请输入职工总数" clearable
								:style="{width: '95%'}" :min="0"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="高级职称" prop="highTotal">
							<el-input-number v-model="formData.highTotal" placeholder="请输入高级职称" clearable
								:style="{width: '95%'}" :min="0"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="中级职称" prop="midTotal">
							<el-input-number v-model="formData.midTotal" placeholder="请输入中级职称" clearable
								:style="{width: '95%'}" :min="0">
							</el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="初级职称" prop="basicTotal">
							<el-input-number v-model="formData.basicTotal" placeholder="请输入初级职称" clearable
								:style="{width: '95%'}" :min="0"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="其它" prop="otherTotal">
							<el-input-number v-model="formData.otherTotal" placeholder="请输入其它" clearable
								:style="{width: '100%'}" :min="0">
							</el-input-number>
						</el-form-item>
					</el-col>
				</el-row>
				<el-divider content-position="left">单位联系人情况</el-divider>
				<el-row :gutter="10" class="app_form_no5">
					<el-col :span="4" class="app_form_no5_man">
						<el-form-item label="单位联系人" prop="contName">
							<el-input v-model="formData.contName" placeholder="请输入单位指定联系人" clearable
								:style="{width: '95%'}"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="座机" prop="contTel">
							<el-input v-model="formData.contTel" placeholder="请输入座机" clearable :style="{width: '95%'}">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="传真" prop="contFax">
							<el-input v-model="formData.contFax" placeholder="请输入传真" clearable
								:style="{width: '100%'}">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="手机" prop="contMobile">
							<el-input v-model="formData.contMobile" placeholder="请输入手机" clearable
								:style="{width: '100%'}">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="电子邮箱" prop="contEmail">
							<el-input  v-model="formData.contEmail" placeholder="请输入电子邮箱"
								clearable :style="{width: '90%'}">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-divider content-position="left">单位负责人情况</el-divider>
				<el-row :gutter="10" class="app_form_06">
					<el-col :span="4" class="app_form_06_man">
						<el-form-item label="单位负责人" prop="chargeName">
							<el-input v-model="formData.chargeName" placeholder="请输入单位负责人" clearable
								:style="{width: '95%'}"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="座机" prop="chargeTel">
							<el-input v-model="formData.chargeTel" placeholder="请输入座机" clearable
								:style="{width: '95%'}">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="传真" prop="chargeFax">
							<el-input v-model="formData.chargeFax" placeholder="请输入传真" clearable
								:style="{width: '100%'}">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="手机" prop="chargeMobile">
							<el-input v-model="formData.chargeMobile" placeholder="请输入手机" clearable
								:style="{width: '100%'}">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="电子邮箱" prop="chargeEmail">
							<el-input  v-model="formData.chargeEmail" placeholder="请输入电子邮箱"
								clearable :style="{width: '90%'}"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="10" class="app_form_07">
					<el-col :span="4" class="app_form_07_man">
						<el-form-item label="法人代表" prop="legalPerson">
							<el-input v-model="formData.legalPerson" placeholder="请输入法人代表" clearable
								:style="{width: '90%'}">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="电话" prop="legalTel">
							<el-input v-model="formData.legalTel" placeholder="请输入电话" clearable
								:style="{width: '100%'}"></el-input>
						</el-form-item>
					</el-col>
					<!-- <el-col :span="3">
						<el-form-item label="申报地区（市）" prop="legalPerson">
							<el-input v-model="formData.applyArea" placeholder="请输入申报地区市" clearable :style="{width: '95%'}">
							</el-input>
						</el-form-item>
					</el-col> -->
					<el-col :span="5">
						<el-form-item label="申报地区市县" prop="applyCounty">
							<el-select v-model="formData.applyCounty" placeholder="申报地区市县" clearable
								:style="{width: '100%'}">
								<el-option v-for="(item, index) in legalAddress" :key="index" :label="item.label"
									:value="item.value" :disabled="item.disabled"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<el-form-item label="是否为高促会会员" prop="position">
							<el-select v-model="formData.position" placeholder="是否为高促会会员" clearable
								:style="{width: '100%'}">
								<el-option v-for="(item, index) in positionOptions" :key="index" :label="item.label"
									:value="item.value" :disabled="item.disabled"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="app_form_07_checkusername">
						<el-form-item label="注册用户名（作为登录账号）" prop="userName">
							<el-input @blur="checkUserName" class="app_form_07_username" v-model="formData.userName"
								placeholder="请输入注册用户名" clearable :style="{width: '100%'}">
							</el-input>
						</el-form-item>
					</el-col>
					<!-- <el-col class="app_form_07_usercheck" :span="1">
						<el-button class="app_form_07_usercheck_btn" v-if="saveIf == 0" type="primary" @click="checkUserName">查重</el-button>
					</el-col> -->
				</el-row>
				<el-row :gutter="10" class="app_form_08">
					<el-col :span="7" class="app_form_08_name">
						<el-form-item label="银行账号" prop="bankAccount">
							<el-input v-model="formData.bankAccount" placeholder="请输入银行账号" clearable>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="银行账号户名" prop="bankUsername">
							<el-input v-model="formData.bankUsername" placeholder="请输入银行账号户名" clearable>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="开户行" prop="bankName">
							<el-input v-model="formData.bankName" placeholder="请输入开户行" clearable>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>

			</el-row>
			<el-form-item size="large">
				<el-button v-if="saveIf == 0" type="primary" @click="checkUserNameAndReport">提交</el-button>
				<el-button v-else-if="saveIf == 1" type="primary" @click="clickToEdit">修改</el-button>
				<el-button v-if="saveIf == 0" @click="resetForm">重置</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	export default {
		name: 'HelloWorld',
		props: [],
		data() {
			return {
				formData: {
					uuid: this.$route.query.uuid,
					bankName: undefined,
					bankUsername: undefined,
					bankAccount: undefined,
					applyCounty: undefined,
					applyArea: undefined,
					legalTel: undefined,
					legalPerson: undefined,
					uniCode: undefined,
					corpName: undefined,
					registerAddress: undefined,
					corpNature: undefined,
					registerFund: undefined,
					formed: undefined,
					contAddress: undefined,
					postCode: undefined,
					techType: undefined,
					corpType: undefined,
					mainProduct: undefined,
					staffTotal: undefined,
					highTotal: undefined,
					midTotal: undefined,
					basicTotal: undefined,
					otherTotal: undefined,
					contName: undefined,
					contTel: undefined,
					contFax: undefined,
					contMobile: undefined,
					contEmail: undefined,
					chargeName: undefined,
					chargeTel: undefined,
					chargeFax: undefined,
					chargeMobile: undefined,
					chargeEmail: undefined,
					position: undefined,
					userName: undefined,
				},
				rules: {

					applyArea: [{
						required: true,
						message: '请输入申报地区（市）',
						trigger: 'blur'
					}],
					corpName: [{
						required: true,
						message: '请输入单位名称',
						trigger: 'blur'
					}],
					registerAddress: [{
						required: true,
						message: '请输入注册地址',
						trigger: 'blur'
					}],
					uniCode: [{
						required: true,
						message: '请输入统一社会信用代码',
						trigger: 'blur'
					}],
					legalPerson: [{
						required: true,
						message: '请输入法人代表',
						trigger: 'blur'
					}],
					legalTel: [{
						required: true,
						message: '请输入电话',
						trigger: 'blur'
					}],
					applyCounty: [{
						required: true,
						message: '请输入申报地区市县',
						trigger: 'blur'
					}],
					corpNature: [{
						required: true,
						message: '请输入单位性质',
						trigger: 'blur'
					}],
					registerFund: [{
							required: true,
							message: '请输入注册资金（万元）',
							trigger: 'blur'
						},
						{
							pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
							message: '不允许输入特殊符号'
						},
					],
					formed: [{
						required: true,
						message: '请输入成立时间',
						trigger: 'change'
					}],
					contAddress: [{
						required: true,
						message: '请输入通讯地址',
						trigger: 'blur'
					}],
					postCode: [{
						required: true,
						message: '请输入邮政编码',
						trigger: 'blur'
					}],
					techType: [{
						required: true,
						message: '请选择技术领域',
						trigger: 'change'
					}],
					corpType: [{
						required: true,
						message: '请选择单位类别',
						trigger: 'change'
					}],
					mainProduct: [{
						required: true,
						message: '请输入主要产品（服务）及技术',
						trigger: 'blur'
					}],
					staffTotal: [{
						required: true,
						message: '请输入职工总数',
						trigger: 'blur'
					}],
					highTotal: [{
						required: true,
						message: '请输入高级职称',
						trigger: 'blur'
					}],
					midTotal: [{
						required: true,
						message: '请输入中级职称',
						trigger: 'blur'
					}],
					basicTotal: [{
						required: true,
						message: '请输入初级职称',
						trigger: 'blur'
					}],
					otherTotal: [{
						required: true,
						message: '请输入其它',
						trigger: 'blur'
					}],
					contName: [{
						required: true,
						message: '请输入单位指定联系人',
						trigger: 'blur'
					}],
					contTel: [{
						required: true,
						message: '请输入座机',
						trigger: 'blur'
					}],
					contFax: [{
						required: true,
						message: '请输入传真',
						trigger: 'blur'
					}],
					contMobile: [{
						required: true,
						message: '请输入手机',
						trigger: 'blur'
					}],
					contEmail: [{
						required: true,
						message: '请输入电子邮箱',
						trigger: 'blur'
					},
					{
						pattern: /\w@\w*\.\w/,
						message: '请输入正确的电子邮箱格式'
					}
						],
					chargeName: [{
						required: true,
						message: '请输入单位负责人',
						trigger: 'blur'
					}],
					chargeTel: [{
						required: true,
						message: '请输入座机',
						trigger: 'blur'
					}],
					chargeFax: [{
						required: true,
						message: '请输入传真',
						trigger: 'blur'
					}],
					chargeMobile: [{
						required: true,
						message: '请输入手机',
						trigger: 'blur'
					}],
					chargeEmail: [{
						required: true,
						message: '请输入电子邮箱',
						trigger: 'blur'
					},
					{
						pattern: /\w@\w*\.\w/,
						message: '请输入正确的电子邮箱格式'
					}],
					position: [{
						required: true,
						message: '请选择是否为高促会会员',
						trigger: 'blur'
					}],
					userName: [{
						required: true,
						message: '请输入注册用户名',
						trigger: 'blur'
					}],
					bankAccount: [{
						required: true,
						message: '请输入银行账号',
						trigger: 'blur'
					}],
					bankUsername: [{
						required: true,
						message: '请输入银行账号户名',
						trigger: 'blur'
					}],
					bankName: [{
						required: true,
						message: '请输入注册用户名',
						trigger: 'blur'
					}],
				},
				techTypeOptions: [{
					"label": "家电",
					"value": "家电"
				}, {
					"label": "机械装备",
					"value": "机械装备"
				}, {
					"label": "电子信息",
					"value": "电子信息"
				}, {
					"label": "光机电一体化",
					"value": "光机电一体化"
				}, {
					"label": "新能源与高效节能",
					"value": "新能源与高效节能"
				}, {
					"label": "新材料",
					"value": "新材料"
				}, {
					"label": "生物医药",
					"value": "生物医药"
				}, {
					"label": "其他",
					"value": "其他"
				}],
				corpTypeOptions: [{
					"label": "国家高企",
					"value": "国家高企"
				}, {
					"label": "市级高企",
					"value": "市级高企"
				}, {
					"label": "民营企业",
					"value": "民营企业"
				}, {
					"label": "中介服务",
					"value": "中介服务"
				}, {
					"label": "政府机构",
					"value": "政府机构"
				}, {
					"label": "科研院校",
					"value": "科研院校"
				}, {
					"label": "其他",
					"value": "其他"
				}],
				corpNature: [{
					"label": "国有企业",
					"value": "国有企业"
				}, {
					"label": "国有控股企业",
					"value": "国有控股企业"
				}, {
					"label": "外资企业",
					"value": "外资企业"
				}, {
					"label": "合资企业",
					"value": "合资企业"
				}, {
					"label": "私营企业",
					"value": "私营企业"
				}, {
					"label": "事业单位",
					"value": "事业单位"
				}, {
					"label": "国家行政机关",
					"value": "国家行政机关"
				}],
				positionOptions: [{
					"label": "是",
					"value": "是"
				}, {
					"label": "否",
					"value": "否"
				}],
				legalAddress: [{
						"label": "江门市科学技术局",
						"value": "江门市科学技术局"
					}, {
						"label": "蓬江区科工商务局",
						"value": "蓬江区科工商务局"
					},
					{
						"label": "江海区科学技术局",
						"value": "江海区科学技术局"
					}, {
						"label": "新会区科工商务局",
						"value": "新会区科工商务局"
					}, {
						"label": "台山市科工商务局",
						"value": "台山市科工商务局"
					}, {
						"label": "开平市科工商务局",
						"value": "开平市科工商务局"
					}, {
						"label": "鹤山市科工商务局",
						"value": "鹤山市科工商务局"
					}, {
						"label": "恩平市科工商务局",
						"value": "恩平市科工商务局"
					},
				],
				// baseUrl: 'http://192.168.3.93:8081/dev-api/api',
				//baseUrl: 'https://gch-wxcxapi.rencaijituan.com/prod-api/api/',
				// baseUrl: 'http://192.168.3.93:7300/gch/corpApply/',
				// baseUrl: 'https://gchapitest.rencaijituan.com/',
				baseUrl: 'https://gch-wxcxapi.rencaijituan.com/gch/corpApply/',
				//0:进行提交操作 1:进行修改操作
				saveIf: 0,
			}
		},
		created() {
			//从路由传参里获取uuid
			console.log('this.$route---->', this.$route);
			let uuid = this.$route.query.uuid;
			// uuid = '3a5405c261694a169ee23f4bf14afb5c';
			console.log(uuid);
			//无uuid
			if (typeof uuid === 'undefined') {
				console.log('reg')
				return;
			}
			//有uuid则自动获取信息
			this.$http({
				method: 'get',
				url: this.baseUrl + 'anonymous/get/apply/' + uuid,
			}).then((response) => { //这里使用了ES6的语法
				console.log('res----->', response) //请求成功返回的数据
				if (response.data.code == 200) {
					//应该进行修改操作
					this.saveIf = 1;
					//根据uuid获取数据成功，开始赋值
					this.formData.corpName = response.data.data.corpName;
					this.formData.registerAddress = response.data.data.registerAddress;
					this.formData.uniCode = response.data.data.uniCode;
					this.formData.corpNature = response.data.data.corpNature;
					this.formData.registerFund = response.data.data.registerFund;
					this.formData.formed = response.data.data.formed;
					this.formData.contAddress = response.data.data.contAddress;
					this.formData.postCode = response.data.data.postCode;
					this.formData.techType = response.data.data.techType;
					this.formData.corpType = response.data.data.corpType;
					this.formData.mainProduct = response.data.data.mainProduct;
					this.formData.staffTotal = response.data.data.staffTotal;
					this.formData.highTotal = response.data.data.highTotal;
					this.formData.midTotal = response.data.data.midTotal;
					this.formData.basicTotal = response.data.data.basicTotal;
					this.formData.otherTotal = response.data.data.otherTotal;
					this.formData.contName = response.data.data.contName;
					this.formData.contTel = response.data.data.contTel;
					this.formData.contFax = response.data.data.contFax;
					this.formData.contMobile = response.data.data.contMobile;
					this.formData.contEmail = response.data.data.contEmail;
					this.formData.chargeName = response.data.data.chargeName;
					this.formData.chargeTel = response.data.data.chargeTel;
					this.formData.chargeFax = response.data.data.chargeFax;
					this.formData.chargeMobile = response.data.data.chargeMobile;
					this.formData.chargeEmail = response.data.data.chargeEmail;
					this.formData.legalPerson = response.data.data.legalPerson;
					this.formData.legalTel = response.data.data.legalTel;
					/* this.formData.applyArea = response.data.data.applyArea; */
					this.formData.applyCounty = response.data.data.applyCounty;
					this.formData.position = response.data.data.position;
					this.formData.userName = response.data.data.userName;
					this.formData.bankAccount = response.data.data.bankAccount;
					this.formData.bankUsername = response.data.data.bankUsername;
					this.formData.bankName = response.data.data.bankName;
				}else{
					alert(response.data.msg)
				}
			})

		},

		methods: {
			resetForm() {
				this.formData.corpName = undefined;
				this.formData.registerAddress = undefined;
				this.formData.uniCode = undefined;
				this.formData.corpNature = undefined;
				this.formData.registerFund = undefined;
				this.formData.formed = undefined;
				this.formData.contAddress = undefined;
				this.formData.postCode = undefined;
				this.formData.techType = undefined;
				this.formData.corpType = undefined;
				this.formData.mainProduct = undefined;
				this.formData.staffTotal = undefined;
				this.formData.highTotal = undefined;
				this.formData.midTotal = undefined;
				this.formData.basicTotal = undefined;
				this.formData.otherTotal = undefined;
				this.formData.contName = undefined;
				this.formData.contTel = undefined;
				this.formData.contFax = undefined;
				this.formData.contMobile = undefined;
				this.formData.contEmail = undefined;
				this.formData.chargeName = undefined;
				this.formData.chargeTel = undefined;
				this.formData.chargeFax = undefined;
				this.formData.chargeMobile = undefined;
				this.formData.chargeEmail = undefined;
				this.formData.legalPerson = undefined;
				this.formData.legalTel = undefined;
				/* this.formData.applyArea = undefined; */
				this.formData.applyCounty = undefined;
				this.formData.position = undefined;
				this.formData.userName = undefined;
				this.formData.bankAccount = undefined;
				this.formData.bankUsername = undefined;
				this.formData.bankName = undefined;
			},
			checkUserName() {
				let specialKey = "[`!@#%^-+_~!$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
				for (var i = 0; i < this.formData.userName.length; i++) {
					if (specialKey.indexOf(this.formData.userName.substr(i, 1)) != -1) {
						alert('不能含有特殊字符！');
						this.formData.userName = '';
						return;
					}
				}

				this.$http({
					method: 'get',
					url: this.baseUrl + 'anonymous/check/' + this.formData.userName,
				}).then((response) => { //这里使用了ES6的语法
					console.log('checkUserName res----->', response); //请求成功返回的数据
					if (response.data.code == 200) {
						alert('用户名可用!');
					} else if (response.data.code == 401) {
						alert('服务器异常');
					} else {
						alert('用户名已被注册!');
						this.formData.userName = '';
					}
				})
			},
			checkUserNameAndReport() {
				this.$refs["formData"].validate(valid => {
					if (valid) {
						if (this.formData.contEmail == '' || this.formData.contEmail == null || this.formData
							.contEmail ==
							undefined) {
							alert('请输入联系人邮箱');
							return;
						}
						if (this.formData.chargeEmail == '' || this.formData.chargeEmail == null || this.formData
							.chargeEmail ==
							undefined) {
							alert('请输入负责人邮箱');
							return;
						}
						let specialKey = "[`!@#%^-+_~!$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
						for (var i = 0; i < this.formData.userName.length; i++) {
							if (specialKey.indexOf(this.formData.userName.substr(i, 1)) != -1) {
								alert('用户名不能含有特殊字符！');
								this.formData.userName = '';
								return;
							}
						}
						this.$http({
							method: 'get',
							url: this.baseUrl + 'anonymous/check/' + this.formData.userName,
						}).then((response) => { //这里使用了ES6的语法
							console.log('checkUserName res----->', response); //请求成功返回的数据
							if (response.data.code == 200) {
								this.clickTo();
							} else if (response.data.code == 401) {
								alert('服务器异常');
							} else {
								alert('用户名已被注册!');
								this.formData.userName = '';
								return;
							}
						})
					}
				})


			},
			//提交
			clickTo() {
				this.$http({
					method: 'post',
					url: this.baseUrl + 'anonymous/submit',
					// url: 'http://192.168.3.93:8081/dev-api/api/apply',
					// url:'http://47.106.12.11:8080/prod-api/api/apply',
					data: this.formData
				}).then((response) => { //这里使用了ES6的语法
					console.log('res----->', response); //请求成功返回的数据
					if (response.data.code == 200) {
						//成功后关闭当前页面以防止再次提交(暂定);
						alert('提交成功!请留意单位联系人邮箱!');
						window.opener = null;
						window.open("about:blank", "_top").close();
					} else if (response.data.code == 500) {
						alert('请输全数据!');
						return;
					} else {
						alert('服务器异常!');
						return;
					}
				})
			},
			clickToEdit() {
				this.$http({
					method: 'put',
					url: this.baseUrl + 'anonymous/update',
					data: this.formData
				}).then((response) => { //这里使用了ES6的语法
					console.log('res----->', response); //请求成功返回的数据
					if (response.data.code == 200) {
						alert('修改成功!');
						window.opener = null;
						window.open("about:blank", "_top").close();
					}else{
						alert(response.data.msg);
					}
				})
			},
			checkContEmail() {
				var regEmail = /\w@\w*\.\w/;
				if (this.formData.contEmail != '' && !regEmail.test(this.formData.contEmail)) {
					alert('邮箱格式不正确!')
					this.formData.contEmail = '';
				}
			},
			checkFuzeEmail() {
				var regEmail = /\w@\w*\.\w/;
				if (this.formData.chargeEmail != '' && !regEmail.test(this.formData.chargeEmail)) {
					alert('邮箱格式不正确!')
					this.formData.chargeEmail = '';
				}
			}
		}

	}
</script>

<style>
	.app-container {}

	.app_form {}

	.app_form_no1 {
		display: flex;
		flex-direction: row;
		text-align: center;
		align-items: center;
	}

	.app_form_no1_name {
		margin-left: 50px;
	}

	.app_form_no2 {

		display: flex;
		flex-direction: row;
		text-align: center;
		align-items: center;
	}

	.app_form_no2_money {

		margin-left: 25px;

	}

	.app_form_no2_time {

		margin-left: -23px;
	}

	.app_form_no3 {

		margin-left: 50px;
	}

	.app_form_no4 {

		padding-left: 200px;
	}

	.app_form_no5 {
		display: flex;
		flex-direction: row;
		text-align: center;
		align-items: center;
	}

	.app_form_no5_man {

		margin-left: 50px;
	}

	.app_form_06 {
		display: flex;
		flex-direction: row;
		text-align: center;
		align-items: center;
	}

	.app_form_06_man {
		margin-left: 50px;
	}

	.app_form_07 {
		display: flex;
		flex-direction: row;
		text-align: center;
		align-items: center;
	}

	.app_form_07_man {
		margin-left: 40px;
	}

	.app_form_07_checkusername {}

	.app_form_07_username {
		margin-left: auto;
	}

	.app_form_07_usercheck {
		height: 50px;
		margin-top: 40px;
	}

	.app_form_07_usercheck_btn {

		height: 35px;
	}

	.app_form_08 {
		display: flex;
		flex-direction: row;
		text-align: center;
		align-items: center;
	}

	.app_form_08_name {
		margin-left: 50px;
	}
</style>
